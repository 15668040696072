import React from "react";
import "./PageTitle.css";

class PageTitle extends React.Component {
  render() {
    const { text, image } = this.props;
    // if (text) {
    //   return <h1 className="page-title">{text}</h1>;
    // } else if (image) {
    return (
      <h1 className="page-title">
        <img src={image} alt={text} className="page-title-image" />
      </h1>
    );
    // } else {
    //   return null;
    // }
  }
}

export default PageTitle;
